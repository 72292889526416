import { createAsyncThunk } from "@reduxjs/toolkit";
import { CustomerInfo } from "types";
import { SCOPES } from "utilities/constants/constants";
import { apiGet, apiPost } from "utilities/api";

export const fetchListOfEntitlements = createAsyncThunk(
	"entitlements/fetch",
	apiGet<CustomerInfo[]>(
		(authConfig, { params: { userId } }: any) =>
			authConfig.FRESHSERVICE_API_ENDPOINTS.GET_ENTITLEMENTS(userId),
		[SCOPES.FRESHSERVICE_READ],
	),
);

export const createEntitlement = createAsyncThunk(
	"entitlements/create",
	apiPost(
		(authConfig, { id: userId }: any) => {
			return authConfig.MS_GRAPH_API_ENDPOINTS.CREATE_ENTITLEMENT(userId);
		},
		[SCOPES.GRAPH_WRITE],
	),
);
