import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { createEntitlement, fetchListOfEntitlements } from "actions/entitlementActions";
import { RootState } from "store";
import { CustomerInfo } from "types";

const entitlementsAdapter = createEntityAdapter<CustomerInfo>(); // TODO: make a entitlement type for this

const entitlementSlice = createSlice({
	name: "entitlements",
	initialState: entitlementsAdapter.getInitialState({
		isLoading: true,
		isFetching: false,
		errorCreatingEntitlement: false,
		activeEntitlement: {} as any, // TODO: Type this properly
		activeStep: 0,
		redeemUrl: null,
	}),
	reducers: {
		setActiveEntitlement: (state, { payload }) => {
			state.activeEntitlement = payload;
		},
		setActiveStep: (state, { payload }) => {
			state.activeStep = payload;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(fetchListOfEntitlements.pending, (state) => {
				state.isFetching = true;
			})
			.addCase(fetchListOfEntitlements.fulfilled, (state, { payload }) => {
				entitlementsAdapter.setAll(
					state,
					payload.map((department: CustomerInfo, id: number) => ({ id, ...department })),
				);
				state.isFetching = false;
				state.isLoading = false;
			});
		builder
			.addCase(createEntitlement.pending, (state) => {
				state.errorCreatingEntitlement = false;
			})
			.addCase(createEntitlement.fulfilled, (state, { payload }) => {
				state.redeemUrl = (payload as any).redeemUrl;
			})
			.addCase(createEntitlement.rejected, (state, { error }) => {
				state.errorCreatingEntitlement = true;
			});
	},
});

export const selectEntitlements = (state: RootState) => state.entitlements;
export const { setActiveEntitlement, setActiveStep } = entitlementSlice.actions;
export const selectActiveEntitlement = (state: RootState) => state.entitlements.activeEntitlement;
export const selectActiveStep = (state: RootState) => state.entitlements.activeStep;
export const selectGuestRedeemInvitationUrl = (state: RootState) => state.entitlements.redeemUrl;
export const selectErrorCreatingEntitlement = (state: RootState) =>
	state.entitlements.errorCreatingEntitlement;

export const entitlementsSelectors = entitlementsAdapter.getSelectors(selectEntitlements);

export default entitlementSlice.reducer;
