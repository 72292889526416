import { Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useAppSelector } from "hooks/hooks";
import { selectActiveEntitlement } from "features/entitlements";

import styles from "./InvitationAccepted.module.scss";

export const InvitationAccepted = () => {
	const [timeLeft, setTimeLeft] = useState(5); // Set initial countdown time

	const activeEntitlement = useAppSelector(selectActiveEntitlement);

	useEffect(() => {
		document.addEventListener("visibilitychange", handleVisibilityChange);

		return () => {
			document.removeEventListener("visibilitychange", handleVisibilityChange);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleVisibilityChange = () => {
		if (document.visibilityState === "visible" && timeLeft > 0) {
			const timer = setInterval(() => {
				setTimeLeft((prevTimeLeft) => {
					if (prevTimeLeft <= 1) {
						clearInterval(timer);
						window.location.reload();
					}
					return prevTimeLeft - 1;
				});
			}, 1000);
		}
	};

	return (
		<Grid container className={styles.globalLoadingScreen}>
			<Typography variant="h2" fontWeight={500}>
				You have accepted the invitation
			</Typography>
			<Typography variant="body1">
				Refreshing page in <b>{timeLeft}</b> seconds to show data from{" "}
				{activeEntitlement?.name}
			</Typography>
		</Grid>
	);
};
