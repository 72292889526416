import { CircularProgress, Grid, InputBase, Typography } from "@mui/material";
import { createEntitlement, fetchListOfEntitlements } from "actions/entitlementActions";
import clsx from "clsx";
import {
	entitlementsSelectors,
	selectActiveStep,
	selectEntitlements,
	setActiveEntitlement,
	setActiveStep,
} from "features/entitlements";
import { setGlobalSnackbarState } from "features/snackbars";
import { useApiOnceWithParams, useAppSelector, useAuth } from "hooks/hooks";
import { useEffect, useState } from "react";
import { SCOPES } from "utilities/constants/constants";
import { PrimaryButton } from "components/Common/Buttons/Button";
import { clearAccessTokensOfPreviousTenant, goToEntitledTenant } from "../entitlementUtils";
import config from "authConfig";

import styles from "./EntitlementList.module.scss";
import { CustomerInfo } from "types";
import { ErrorOutlineOutlined, VerifiedOutlined } from "@mui/icons-material";
import { TruncatableTypography } from "components/Common/TruncateableTypography";

interface EntitlementListProps {
	setIsCreatingEntitlement: (value: boolean) => void;
}

export const EntitlementList = ({ setIsCreatingEntitlement }: EntitlementListProps) => {
	const {
		dispatch,
		auth,
		auth: { instance, account },
	} = useAuth();

	const localAccountId = account.homeAccountId.split(".")[0];
	const localTenantId = account.homeAccountId.split(".")[1];
	const entitlementsState = useAppSelector(selectEntitlements);
	const entitlements = useAppSelector(entitlementsSelectors.selectEntities);
	useApiOnceWithParams(fetchListOfEntitlements, entitlementsState, {
		userId: localAccountId,
	});
	const [searchValue, setSearchValue] = useState("");
	const [shownEntitlements, setShownEntitlements] = useState(entitlements);
	const activeStep = useAppSelector(selectActiveStep);

	useEffect(() => {
		const sessionStorageEntitlement = sessionStorage.getItem("entitlement");
		if (sessionStorageEntitlement && sessionStorageEntitlement !== localTenantId) {
			// If we're already in an entitlement, only show home tenant of account as available entitlements
			const onlyHomeTenant = Object.fromEntries(
				Object.entries(entitlements).filter(
					([id, entitlement]) => entitlement?.primaryTenantId === localTenantId,
				),
			);
			setShownEntitlements(onlyHomeTenant);
			return;
		}

		if (searchValue) {
			const filteredEntitlements = Object.fromEntries(
				Object.entries(entitlements).filter(([id, entitlement]) =>
					entitlement?.departmentName.toLowerCase().includes(searchValue.toLowerCase()),
				),
			);
			setShownEntitlements(filteredEntitlements);
			return;
		}

		setShownEntitlements(entitlements);
	}, [searchValue, entitlements, localTenantId]);

	const handleSwitchTenant = async (entitlement: CustomerInfo) => {
		const tenantIdOfEntitlement = entitlement.primaryTenantId.split(",")[0];

		if (!tenantIdOfEntitlement) {
			console.error("No tenant id", entitlement);
			return;
		}

		try {
			const { accessToken } = await instance.acquireTokenSilent({
				scopes: [SCOPES.GRAPH_READ],
				account,
				authority: `https://login.microsoftonline.com/${tenantIdOfEntitlement}`,
				forceRefresh: true,
			});

			// If we're able to get an access token, we're already a member of the tenant
			// Thus we can just reload the page to get the data from the new tenant
			if (accessToken) {
				goToEntitledTenant(tenantIdOfEntitlement);
				return;
			}
		} catch (error) {
			// Expected error when not a member of the entitled tenant - continue to create entitlement
		}

		dispatch(setActiveStep(1));

		setIsCreatingEntitlement(true);
		dispatch(setActiveEntitlement(entitlement));
		sessionStorage.setItem("entitlement", entitlement.primaryTenantId.split(",")[0]);
		const body = {
			inviteToTenant: tenantIdOfEntitlement,
			appId: config.APP_ID,
			displayName: account.name,
			mail: account?.idTokenClaims?.email,
			redirectUrl: window.location.origin + "/autoclose",
		};
		const homeAccountId = account?.homeAccountId.split(".")[0];
		const res = await dispatch(createEntitlement({ auth, body, id: homeAccountId }));
		const rejected = res.meta.requestStatus === "rejected";

		if (rejected) {
			sessionStorage.removeItem("entitlement");
		} else {
			// Successfull creation of entitlement -> clear access tokens of previous tenant
			clearAccessTokensOfPreviousTenant();
		}

		setIsCreatingEntitlement(false);
	};

	return (
		<Grid container className={clsx({ [styles.hide]: activeStep !== 0 })}>
			<InputBase
				value={searchValue}
				className={styles.input}
				placeholder="Search"
				onChange={(e) => setSearchValue(e.target.value)}
			/>
			<Grid container className={styles.loadingContainer}>
				{entitlementsState.isLoading && <CircularProgress />}
			</Grid>
			<Grid container className={styles.container}>
				{Object.entries(shownEntitlements).map(([id, entitlement]) => (
					<EntitlementRow
						entitlement={entitlement as CustomerInfo}
						key={id}
						handleSwitchTenant={(entitlement) => handleSwitchTenant(entitlement)}
					/>
				))}
			</Grid>
		</Grid>
	);
};

interface EntitlementRowProps {
	entitlement: CustomerInfo;
	handleSwitchTenant: (entitlement: any) => void;
}

const EntitlementRow = ({ entitlement, handleSwitchTenant }: EntitlementRowProps) => {
	return (
		<Grid container className={styles.entitlement}>
			<Grid container item className={styles.header}>
				<TruncatableTypography
					variant="h2"
					fontWeight={500}
					className={styles.name}
					maxCharLength={40}
				>
					{entitlement.departmentName}
				</TruncatableTypography>
				<BooleanInfoField explanation="Portal access" value={entitlement.hasPortalAccess} />
				<BooleanInfoField
					explanation="Your employees"
					value={entitlement.yourEmployeesEnabled}
				/>
				<BooleanInfoField
					explanation="Your IT-Systems"
					value={entitlement.yourItSystemsEnabled}
				/>
				<BooleanInfoField
					explanation="Extended security"
					value={entitlement.extendedSecurity}
				/>
				<BooleanInfoField
					explanation="Can purchase hardware"
					value={entitlement.hasKomplettRelationship}
				/>
			</Grid>
			<Grid container className={styles.entitlementPart}>
				<Grid container item className={styles.row}>
					<InfoField explanation="Id" value={entitlement.departmentId} copyable />
					<InfoField
						explanation="Tenant id"
						value={entitlement.primaryTenantId}
						copyable
					/>
					<InfoField
						explanation="Sales"
						value={entitlement.commercialLead || "No sales contact"}
						copyable
					/>
				</Grid>
			</Grid>
			<Grid container className={styles.right}>
				<PrimaryButton
					variantStyle="contained"
					text="Switch"
					action={() => handleSwitchTenant(entitlement)}
					size="small"
				/>
			</Grid>
		</Grid>
	);
};

interface InfoFieldProps {
	explanation: string;
	value: string | number;
	copyable?: boolean;
}

const InfoField = ({ explanation, value, copyable }: InfoFieldProps) => {
	const { dispatch } = useAuth();
	const handleCopyToClipboard = () => {
		navigator.clipboard.writeText(value.toString());
		dispatch(
			setGlobalSnackbarState({
				snackbarText: `Copied ${explanation} to clipboard`,
				duration: 3000,
			}),
		);
	};
	return (
		<Grid item className={styles.infoField}>
			<Typography variant="body1" fontWeight={500} className={styles.explanation}>
				{explanation}
			</Typography>
			<Typography
				variant="body1"
				className={clsx({ [styles.copyableText]: copyable }, styles.text)}
				onClick={() => copyable && handleCopyToClipboard()}
			>
				{value}
			</Typography>
		</Grid>
	);
};

interface BooleanInfoFieldProps {
	explanation: string;
	value: boolean;
}

const BooleanInfoField = ({ explanation, value }: BooleanInfoFieldProps) => {
	return (
		<Grid item className={styles.infoField}>
			<Typography variant="body1" fontWeight={500} className={styles.explanation}>
				{explanation}
			</Typography>
			<Grid item className={styles.booleanContainer}>
				{value ? (
					<VerifiedOutlined color="success" />
				) : (
					<ErrorOutlineOutlined color="error" />
				)}
			</Grid>
		</Grid>
	);
};
